import styled from '@emotion/styled'
import { useRouter } from 'next/router'

import onClickEvents from '@helpers/home/on-click-callbacks'
import getPath from '@helpers/path'
import ROUTES from '@helpers/routes'
import Colors from 'microcomponents/colors'

import { TEST_IDS } from './test/constants'

import { arrayOf, bool, func, oneOfType, shape, string } from 'prop-types'

const FourTileLayout = (props) => {
  const router = useRouter()

  if (props.tiles) {
    const bannerName = props.banner_name
    const buttonRedirectLocation = props.call_to_action ? props.call_to_action.url : ROUTES.MENU
    const { href: buttonRedirect } = getPath(
      { pathname: buttonRedirectLocation, query: router.query },
      { removeParams: ['slug'] }
    )

    return (
      <TileContainer data-e2eid={TEST_IDS.FOUR_TILE_LAYOUT}>
        <Title>{props.section_title}</Title>
        <TitleDescription>{props.section_text}</TitleDescription>
        {props.tiles &&
          props.tiles.map((tile, i) => {
            const bannerItem = tile.banner_item
            const tileRedirectLocation = tile.link ? tile.link.url : ROUTES.MENU
            const { href: tileRedirect } = getPath(
              { pathname: tileRedirectLocation, query: router.query },
              { removeParams: ['slug'] }
            )

            return (
              <Tile key={i} onClick={() => onClickEvents(bannerName, tileRedirect, props.toggleAddress, bannerItem)}>
                <TileImage
                  src={tile.image.url}
                  alt={tile.image.alt || tile.link.title}
                  srcSet={`${tile.image.sizes.medium_large} ${tile.image.sizes['medium_large-width']}w, ${tile.image.sizes.mobileImage} ${tile.image.sizes['mobileImage-width']}w`}
                  sizes={`(max-width: 767px) ${tile.image.sizes['mobileImage-width']}w, ${tile.image.sizes['medium_large-width']}w`}
                />
              </Tile>
            )
          })}
        <ShopNow onClick={() => onClickEvents(bannerName, buttonRedirect, props.toggleAddress, 'shop_now')}>
          {props.call_to_action.title || 'SHOP NOW'}
        </ShopNow>
      </TileContainer>
    )
  } else {
    return null
  }
}

FourTileLayout.propTypes = {
  banner_name: string,
  call_to_action: shape({
    title: string,
    url: string
  }),
  section_title: oneOfType([bool, string]),
  section_text: oneOfType([bool, string]),
  tiles: oneOfType([
    bool,
    arrayOf(
      shape({
        image: shape({
          url: string,
          link_text: string
        })
      })
    )
  ]),
  toggleAddress: func
}

const colorMap = {
  black: Colors.homepageRedesign[0],
  yellow: Colors.homepageRedesign[1]
}

const Title = styled.h3`
  font-size: 24px;
  font-family: 'Suisse Intl Mono Bold', monospace;
`

const TitleDescription = styled.p`
  text-align: center;
  font-family: 'Suisse Intl Mono Regular', monospace;
  font-size: 14px;
`

const TileImage = styled.img``

const TileContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  a {
    color: ${colorMap.black};
  }

  h3,
  p {
    display: inherit;
    flex-flow: inherit;
    justify-content: inherit;
  }

  // Desktop view
  @media (min-width: 767px) {
    max-width: 955px;
    margin-left: auto;
    margin-right: auto;

    h3 {
      margin-top: 30px;
    }

    h3,
    p {
      width: 100%;
    }

    p {
      margin-bottom: 30px;
      padding: 0 210px;
    }
  }

  // Mobile view
  @media (max-width: 767px) {
    h3 {
      margin: 90px 50px 5px;
      text-align: center;
    }

    p {
      max-width: 385px;
      margin: 30px 40px;
    }
  }
`

const Tile = styled.a`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  // Desktop view
  @media (min-width: 767px) {
    margin: 7px 15px;

    img {
      width: 400px;
      height: 100%;
    }
  }

  // Mobile view
  @media (max-width: 767px) {
    img {
      width: 380px;
      height: 100%;
    }
  }
`

const ShopNow = styled.a`
  background-color: ${colorMap.yellow};
  font-family: Gilroy;
  font-style: italic;
  line-height: 1.24;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.27);
  padding-top: 10px;
  text-align: center;
  flex-basis: 100%;
  justify-content: center;
  font-size: 17px;
  min-width: 129px;
  height: 40px;

  // Desktop view
  @media (min-width: 767px) {
    margin: 70px 439px;
  }

  // Mobile view
  @media (max-width: 767px) {
    margin: 20px 352px 80px;
  }
`

export default FourTileLayout
