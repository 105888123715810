import { encode } from 'querystring'

import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'

import withPage from '@/hocs/page'
import HomePage from '@/stories/home'
import getSplitTreatments from '@bff/middleware/request-split-treatments'
import LegacyHomePage from '@components/home'
import { SPLIT_EXPERIMENTS, SPLIT_TREATMENTS } from '@helpers/constants'
import ROUTES from '@helpers/routes'
import getWordpressData from '@helpers/word-press/get-wordpress'

export async function getServerSideProps({ req, query }: GetServerSidePropsContext) {
  const { cookies } = req
  const hasLocationSet = cookies.eaze_coordinates && cookies.eaze_placeId
  // cookies.eaze_loggedin is a boolean in a string ('true' || 'false') so check for existence and parse
  const isLoggedIn = cookies.eaze_loggedin && JSON.parse(cookies.eaze_loggedin)

  // Grab Redesign Split Feature Flag
  const splitAnonIdExperiments = [SPLIT_EXPERIMENTS.REDESIGN]
  const { eazeAnonId, splitTreatments } = await getSplitTreatments(splitAnonIdExperiments, req, false, {
    userId: req.cookies.eaze_userId
  })

  if (hasLocationSet || isLoggedIn) {
    const queryParams = new URLSearchParams(encode(query)).toString()
    const destination = queryParams ? `${ROUTES.MENU}?${queryParams}` : ROUTES.MENU
    return {
      redirect: {
        destination,
        permanent: false
      }
    }
  }

  const data = await getWordpressData('landing')

  return {
    props: {
      ...data,
      eazeAnonId,
      splitTreatments
    }
  }
}

function IndexPage(props: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const showRedesign = props.splitTreatments[SPLIT_EXPERIMENTS.REDESIGN].treatment === SPLIT_TREATMENTS.REDESIGN.ON
  return showRedesign ? <HomePage {...props} /> : <LegacyHomePage {...props} />
}

export default withPage(IndexPage)
